<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block />

        {{ page_title }}
      </h3>

      <div class="form-group mt-3">
        <input type="radio" class="btn-check" name="options-outlined" id="show_only-my"
               @input="() => {
                 filter_cars.show_only = 'my';
                 updateFilters();
               }" autocomplete="off" :checked="filter_cars.show_only === 'my'">
        <label class="btn btn-outline-primary" for="show_only-my">Мои</label>

        <input type="radio" class="btn-check" name="options-outlined" id="show_only-all"
               @input="() => {
                 filter_cars.show_only = 'all';
                 updateFilters();
               }" autocomplete="off" :checked="filter_cars.show_only === 'all'">
        <label class="btn btn-outline-primary" for="show_only-all">Все</label>
      </div>
    </div>

    <div class="box-body">
      <transition name="fade">
        <div class="form-group">
          <div class="row">
            <label class="col-xl-3 col-md-6 col-sm-12 mb-1" for="filter-by-branch">
              <multiselect :allow-empty="true"
                           :options="branches"
                           :searchable="false"
                           :show-labels="true"
                           id="filter-by-branch"
                           @input="() => {
                             filter_cars.branch_id = branch ? branch.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="name"
                           placeholder="Филиал"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Филиал"
                           track-by="id"
                           v-model="branch">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
                <template slot="singleLabel" slot-scope="props">
                  {{ props.option.title }}, {{ props.option.address }}
                </template>
                <template slot="option" slot-scope="props">
                  {{ props.option.title }}, <span class="text-muted">{{ props.option.address }}</span>
                </template>
              </multiselect>
            </label>

            <div v-if="filter_cars.show_only !== 'my'" class="col-xl-3 col-md-6 col-sm-12 mb-1">
              <multiselect :allow-empty="true"
                           :options="employees"
                           :searchable="true"
                           id="filter-by-responsible"
                           @input="() => {
                             filter_cars.responsible_id = responsible ? responsible.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="title"
                           placeholder="Ответственный"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Ответственный"
                           track-by="id"
                           v-model="responsible">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </div>

            <div class="col-xl-3 col-md-6 col-sm-12 mb-1">
              <multiselect :allow-empty="true"
                           :options="marks"
                           :searchable="true"
                           id="filter-by-mark"
                           @input="() => {
                             filter_cars.mark_id = mark ? mark.id : null;
                             updateFilters();
                           }"
                           @select="getCarModels"
                           deselect-label="Убрать"
                           label="name"
                           placeholder="Марка"
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Марка"
                           track-by="id"
                           v-model="mark">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </div>

            <div class="col-xl-3 col-md-6 col-sm-12 mb-1">
              <multiselect :allow-empty="true"
                           :disabled="!models || models.length === 0"
                           :options="models"
                           :searchable="true"
                           id="filter-by-model"
                           @input="() => {
                             filter_cars.model_id = model ? model.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="name"
                           placeholder="Модель"
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Модель"
                           track-by="id"
                           v-model="model">
                <template slot="placeholder">
                  <i class="fas font-scale fa-circle-notch fa-spin text-blue" v-if="onProgressCarModels === true" />
                  Модель
                </template>
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-1 col-md-6 col-sm-12 mb-1">
              <input class="form-control"
                     id="year_min"
                     name="year_min"
                     placeholder="Год, с"
                     title="Год, с"
                     type="number"
                     @input="updateFilters();"
                     v-model="filter_cars.year_min">
            </div>

            <div class="col-xl-1 col-md-6 col-sm-12 mb-1">
              <input class="form-control"
                     id="year_max"
                     name="year_max"
                     placeholder="Год, по"
                     title="Год, по"
                     type="number"
                     @input="updateFilters();"
                     v-model="filter_cars.year_max">
            </div>

            <div class="col-xl-1 col-md-6 col-sm-12 mb-1">
              <input class="form-control"
                     id="price_min"
                     name="price_min"
                     placeholder="Цена, от"
                     title="Цена, от"
                     type="number"
                     @input="updateFilters();"
                     v-model="filter_cars.price_min">
            </div>

            <div class="col-xl-1 col-md-6 col-sm-12 mb-1">
              <input class="form-control"
                     id="price_max"
                     name="price_max"
                     placeholder="Цена, по"
                     title="Цена, по"
                     type="number"
                     @input="updateFilters();"
                     v-model="filter_cars.price_max">
            </div>

            <div class="col-xl-2 col-md-6 col-sm-12 mb-1">
              <multiselect :allow-empty="true"
                           :options="types"
                           :searchable="true"
                           id="filter-by-types"
                           @input="() => {
                             filter_cars.type_id = type ? type.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="title"
                           placeholder="Тип кузова"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Тип кузова"
                           track-by="id"
                           v-model="type">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </div>

            <div class="col-xl-2 col-md-6 col-sm-12 mb-1">
              <multiselect :allow-empty="true"
                           :options="gearboxes"
                           :searchable="true"
                           id="filter-by-gearbox"
                           @input="() => {
                             filter_cars.gearbox_id = gearbox ? gearbox.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="title"
                           placeholder="КПП"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="КПП"
                           track-by="id"
                           v-model="gearbox">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </div>

            <div class="col-xl-2 col-md-6 col-sm-12 mb-1">
              <multiselect :allow-empty="true"
                           :options="fuels"
                           :searchable="true"
                           id="filter-by-fuel"
                           @input="() => {
                             filter_cars.fuel_id = fuel ? fuel.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="title"
                           placeholder="Топливо"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Топливо"
                           track-by="id"
                           v-model="fuel">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </div>

            <div class="col-xl-2 col-md-6 col-sm-12 mb-1">
              <multiselect :allow-empty="true"
                           :options="origins"
                           :searchable="true"
                           id="filter-by-origin"
                           @input="() => {
                             filter_cars.origin_id = origin ? origin.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="title"
                           placeholder="Происхождение"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Происхождение"
                           track-by="id"
                           v-model="origin">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </div>
          </div>
        </div>
      </transition>

      <table-items :filters_query="filter_cars"
                   :relations="relations"
                   component="car-inspections-item"
                   :columns="columns"
                   api_resource="/cars/inspections">
      </table-items>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import BranchService from "@/services/BranchService";
import Multiselect from "vue-multiselect";
import TableItems from "@/components/pagination/table-items.vue";

Vue.component('car-inspections-item', (resolve) => {
  return require(['./item'], resolve);
});

export default {
  name: "cars-inspections",

  components: {
    TableItems,
    Multiselect,
  },

  props: {

  },

  metaInfo() {
    return {
      title: this.page_title,
    };
  },

  data: () => ({
    page_title: "Акты осмотров",

    branches: [],
    branch: null,
    employees: [],
    responsible: null,
    marks: [],
    mark: null,
    models: [],
    model: [],
    origins: [],
    origin: null,
    types: [],
    type: null,
    gearboxes: [],
    gearbox: null,
    fuels: [],
    fuel: null,

    columns: [
      {
        title: 'Автомобиль',
        class: null,
        style: null,
      },
      {
        title: 'Акты осмотра',
        class: null,
        style: null,
      },
    ],
    relations: [
      "inspections",
      "branch",
      "equipments",
      "specification.gearbox",
      "specification.fuel",
      "specification.color",
      "specification.transmission",
      "origin",
      "sold",
      "creator",
      "editor",
    ],

    filter_cars: {
      show_only: null,
      branch_id: null,
      responsible_id: null,
      mark_id: null,
      model_id: null,
      origin_id: null,
      type_id: null,
      year_min: null,
      year_max: null,
      price_min: null,
      price_max: null,
      gearbox_id: null,
      fuel_id: null,
    },

    onProgressCarModels: false,
  }),

  async created() {
    try {
      if ([1, 4, 5, 6, 7, 8, 9].includes(this.$store.getters["auth/authUser"].role.id)) {
        this.filter_cars.show_only = "all";
      } else {
        this.filter_cars.show_only = "my";
      }

      const [
        employees_response,
        car_marks,
        origins_response,
        types_response,
        gearboxes_response,
        fuels_response
      ] = await axios.all([
        API.apiClient.get("/employees", {
          params: this.$apiRequestParams.filters_list,
        }),
        API.apiClient.get("/cars/marks?available=1", {
          params: {
            is_paginated: 0,
            sorting: "asc",
            sort_column: "name",
            filters_query: {
              only_available: true,
            },
          },
        }),
        API.apiClient.get("/cars/origins"),
        API.apiClient.get("/cars/types"),
        API.apiClient.get("/cars/gearboxes"),
        API.apiClient.get("/cars/fuels")
      ]);

      this.branches = await BranchService.getItems();
      this.employees = employees_response.data;
      this.marks = car_marks.data;
      this.origins = origins_response.data;
      this.types = types_response.data;
      this.gearboxes = gearboxes_response.data;
      this.fuels = fuels_response.data;
    } catch (error) {
      errorHandler(error);
    }
  },

  methods: {
    async getCarModels(mark) {
      try {
        this.onProgressCarModels = true;

        this.filter_cars.model_id = null;
        this.models = [];
        this.model = null;

        const models_response = await API.apiClient.get(
          "/cars/mark/" + mark.id + "/models/available",
          {
            params: {
              is_paginated: 0,
              sorting: "asc",
              sort_column: "name",
            },
          },
        );

        this.models = models_response.data;
      } catch (error) {
        errorHandler(error);
      }

      this.onProgressCarModels = false;
    },

    updateFilters() {
      this.EventBus.$emit('table-filter-changed');
    },
  },
};
</script>

<style scoped>

</style>
